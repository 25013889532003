<template>
  <section class="panel" panel-position="0">
    <!-- <section style="display: inline-flex; width: 100%; height: 100%; position: relative">-->
    <section
      style="width: 100%; height: 100%; position: relative; overflow: hidden"
    >
      <section class="leftPart">
        <!-- TITLE -->
        <p class="navigation_title">
          <a @click="back">Mes vidéos</a>
          <a> / </a>
          <a>{{ vdo_name }} </a>
        </p>

        <!-- AUTOSAVE -->
        <AutoSaveInfo :data="autoSave" @save="update()" class="autosave" />

        <!-- PLAYER -->
        <section class="block_playerTimeline center">
          <vue-plyr
            @timeupdate="timeUpdate"
            ref="plyr"
            :options="optionsPlyr"
            v-if="sources.files != undefined"
          >
            <video controls crossorigin playsinline>
              <source
                v-for="src in sources.files"
                v-bind:key="src"
                :size="src.resolution"
                :src="src.player"
                type="video/mp4"
              />
              <track
                v-for="sub in sources.subtitles"
                v-bind:key="sub"
                kind="captions"
                :label="sub.label"
                :src="sub.link"
                :srclang="sub.language"
              />
            </video>
          </vue-plyr>
        </section>

        <!-- TIMELINE -->
        <div class="timeline_container center">
          <div class="row_timeline center" v-if="vdo_timeline.length != 0">
            <div
              v-for="(timeline, index) in vdo_timeline"
              :key="index"
              @click="playPart(index)"
              :id="index == index_currentPart ? 'currentPart' : ''"
              :ref="index == index_currentPart ? 'currentPart' : ''"
            >
              <div
                v-if="timeline.clip_type == 0 && timeline.anim_type == 0"
                style="--background_color: 68, 188, 225"
              >
                <img
                  :class="
                    index != index_currentPart ? 'disable_icon_timeline' : ''
                  "
                  src="../../../assets/animation.svg"
                  class="icon"
                />
              </div>
              <div
                v-if="timeline.clip_type == 0 && timeline.anim_type == 1"
                style="--background_color: 68, 227, 169"
              >
                <img
                  :class="
                    index != index_currentPart ? 'disable_icon_timeline' : ''
                  "
                  src="../../../assets/title.svg"
                  class="icon"
                />
              </div>
              <div
                v-if="timeline.clip_type == 1"
                style="--background_color: 244, 180, 106"
              >
                <img
                  :class="
                    index != index_currentPart ? 'disable_icon_timeline' : ''
                  "
                  src="../../../assets/icon_script_part_speaker.svg"
                  class="icon"
                />
              </div>
              <div
                v-if="timeline.clip_type == 0 && timeline.anim_type == 2"
                style="--background_color: 194, 153, 245"
              >
                <img
                  :class="
                    index !== index_currentPart ? 'disable_icon_timeline' : ''
                  "
                  src="../../../assets/animation.svg"
                  class="icon"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="container_button_regenerate">
          <p
            id="button_regenerate"
            class="button"
            ref="button_regenerate"
            :class="autoSave.status != 1 ? 'disableButtons' : ''"
            @click="
              autoSave.status == 1
                ? regenerateVideo()
                    .then(() => {
                      back();
                    })
                    .catch((e) => {
                      console.error(e);
                    })
                : undefined
            "
          >
            <span class="loading_spinner center"></span>
            <a class="textButton">Demander les modifications</a>
          </p>
        </div>
      </section>
      <section
        id="panelUpdate"
        v-if="vdo_timeline[index_currentPart] != undefined"
      >
        <!-- Animation modification -->
        <h1 v-if="vdo_timeline[index_currentPart].clip_type == 0">
          Modification de l'animation
        </h1>

        <section
          class="animationModification"
          v-if="vdo_timeline[index_currentPart].clip_type == 0"
        >
          <transition name="fade">
            <animationViewer
              class="animation center undraggable"
              :autoplay="true"
              :animation_id="select_id_anim"
              @features="current_features"
              :features_input="review[index_review_currentPart].anim_features"
              :wait_anim_end_to_init="false"
            />
          </transition>

          <!-- <section class="animationFeatures center" v-if="review[index_review_currentPart].anim_features != undefined && review[index_review_currentPart].anim_features != null"> -->
          <section class="animationFeatures center">
            <div
              v-for="(feature, index) in availiable_features"
              :key="feature"
              :style="feature.user_input != 'color' ? 'width:100%;' : ''"
              :userInput="feature.user_input"
            >
              <label>{{ feature.name }}</label>
              <div v-if="feature.user_input == 'color'">
                <span
                  type="color"
                  :style="{
                    'background-color': formatColorEntryToHex(
                      feature.applied_value || ''
                    ),
                  }"
                  @click="$refs['ident_color' + index].click()"
                ></span>
                <input
                  :ref="'ident_color' + index"
                  type="color"
                  @change="change_feature($event, feature)"
                />
                <input
                  class="featureInputColorValue"
                  type="text"
                  :value="formatColorEntryToHex(feature.applied_value || '')"
                  @change="change_feature($event, feature)"
                  placeholder="#FFFFFF"
                  autocomplete="off"
                  data-lpignore="true"
                  data-form-type="other"
                />
              </div>

              <!-- TEXT FEATURE -->
              <div
                v-if="
                  feature.user_input == 'text' &&
                  feature.applied_value != undefined
                "
              >
                <editableText
                  class="featureInputText"
                  :value="feature.value || feature.applied_value"
                  @finished="change_feature($event, feature)"
                  :editable="true"
                  type="text"
                />
              </div>

              <!-- TYPO SELECT FEATURE -->
              <div
                v-if="
                  feature.user_input == 'font' &&
                  feature.applied_value != undefined
                "
              >
                <SelectDropdown
                  class="typoSelect"
                  :choices="font_name_list_selector"
                  :placeholder="font_selector_placeholder"
                  :value="feature.applied_value"
                  @mounted="init_font_animation_name_list()"
                  @select="change_feature($event, feature)"
                />
              </div>

              <!-- IMAGE FEATURE -->
              <div
                v-if="
                  feature.user_input == 'image' &&
                  feature.applied_value != undefined
                "
              >
                <div @click="$refs.logoUploader.click()" id="logo_area">
                  <input
                    type="file"
                    @change="change_feature($event, feature)"
                    hidden
                    ref="logoUploader"
                    accept=".pdf,.jpg,.jpeg,.png"
                    max="1"
                  />

                  <img v-if="logoUploaded != null" :src="logoUploaded" alt="" />
                  <div v-if="logoUploaded == null" class="dropDownZone">
                    <p class="center">Déposez votre logo</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- BOUTON RESETS AND APPLY ALL -->
          <div
            class="animationModificationButtons center"
            v-if="vdo_timeline[index_currentPart].clip_type == 0"
          >
            <button @click="applyToAllTitle" class="button">
              Appliquer à tous
            </button>
            <button
              v-if="feature_has_modifications"
              @click="reloadOriginalAnim"
              class="button"
            >
              Valeurs d’origine
            </button>
          </div>
        </section>

        <!-- Demande de modification-->
        <h1 v-if="vdo_timeline[index_currentPart].clip_type == 1">
          Demande de modifications
        </h1>
        <section
          class="reviewCommentPanel"
          v-if="vdo_timeline[index_currentPart].clip_type == 1"
        >
          <div class="addCommentContainer center">
            <p class="timecode center" v-if="upt_textPart != 0">
              <img src="../../../assets/chronometre.png" />
              {{ duration_render(currentTime) }}
            </p>
            <div v-if="upt_textPart != 0" class="addCommentButtons">
              <button
                class="button"
                :class="autoSave.status != 1 ? 'disableButtons' : ''"
                :disabled="autoSave.status != 1"
                @click="
                  upt_textPart = 0;
                  $refs.plyr.player.pause();
                "
              >
                Ajout texte
              </button>
              <button
                class="button"
                :class="autoSave.status != 1 ? 'disableButtons' : ''"
                :disabled="autoSave.status != 1"
                @click="
                  upt_textPart = 1;
                  $refs.plyr.player.pause();
                  $refs.reviewAttachementUploader.click();
                "
              >
                Ajout visuel
              </button>
            </div>
            <input
              type="file"
              @change="uploadReviewAttachement()"
              hidden
              ref="reviewAttachementUploader"
              accept="image/*, video/*"
              max="1"
            />

            <!-- ADD A TEXT REVIEW-->
            <p
              class="addReviewLabel"
              v-if="upt_textPart != null && upt_textPart == 0"
              @click="
                upt_textPart = null;
                reset_review_request_inputs();
              "
            >
              Texte précis à faire apparaître
            </p>
            <div class="addTextComment_container">
              <a class="timecode" v-if="upt_textPart == 0"
                >{{ duration_render(currentTime) }} •</a
              >
              <input
                v-if="upt_textPart == 0"
                type="text"
                :placeholder="placeholder_input"
                v-model="input_content"
                class="textCommentInput"
              />
              <img
                v-if="upt_textPart == 0"
                src="../../../assets/send_arrow.png"
                alt=""
                class="submitReviewRequest"
                @click="sumbit_review_request()"
              />
              <a
                v-if="upt_textPart == 0"
                class="cancelCommentReview"
                @click="
                  upt_textPart = null;
                  reset_review_request_inputs();
                "
                >Annuler</a
              >
            </div>
          </div>

          <!-- REVIEW LIST -->
          <div class="reviewRequests center">
            <div
              v-for="(content, index) in review[index_review_currentPart]
                .review_content"
              :key="content"
              class="reviewRequest"
            >
              <span class="timecode">{{ duration_render(content.time) }}</span>
              <span class="type_Review">{{
                type_reviewContent(content.type)
              }}</span>
              <editableText
                v-bind:value="content.value"
                @finished="edit_review_content($event, index)"
                :editable="content.type == 0"
                type="text"
                class="reviewContent"
              />
              <div class="trashContainter">
                <span
                  :class="{ trash: true, hide: false }"
                  @click="delete_contentReview(index)"
                >
                  <span></span><i></i
                ></span>
              </div>
            </div>
          </div>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
//NPM
import axios from "axios";
import emitter from "tiny-emitter/instance";
import FormData from "form-data";

// LIB CUSTOM
import snackbar from "../../Object/snackbar";
import setVideoViewer from "../../../lib/setVideoViewer";
import file_api from "../../../api/file";

// COMPONENTS
import SelectDropdown from "../../Object/SelectDropdown/SelectDropdown";
import editableText from "../../Object/editableText";
import navigationTab from "../../Object/navigation-tab/navigation-tab";
import animationViewer from "../../Object/animationViewer";
import AutoSaveInfo from "../../Object/AutoSaveInfo.vue";

//STORE
import Vuex from "vuex";
import store from "../../../store/index";

export default {
  name: "panelReview",
  components: {
    animationViewer,
    SelectDropdown,
    AutoSaveInfo,
    editableText,
    navigationTab,
  },
  props: ["vdo_id"],
  emits: ["finished", "mounted"],
  store: { store },
  data: function () {
    return {
      availiable_features: [],
      pannel_updated: false,
      vdo_name: "",
      review: null,
      player: null,
      upt_textPart: null,
      currentTime: 0,
      index_currentPart: 0,
      current_identity: {
        ident_font: null,
      },
      input_content: "",
      font_selector_placeholder: "Police d'écriture",
      file: null,
      logoUploaded: null,
      cantDisplayLogo: false,
      src_player: null,
      autoSave: {
        status: 1,
        lastKeyUp: Date,
        lastSave: "",
      },
      vdo_timeline: [],
      sources: {},

      optionsPlyr: {
        captions: { active: true, language: "auto", update: false },
      },
    };
  },

  mounted: function () {
    setTimeout(() => {
      emitter.emit("click_location", "panel1");
    }, 500);

    setTimeout(() => {
      this.$emit("mounted");
    }, 1000);

    this.getOneVideo({
      id: this.vdo_id,
      query: {
        subtitles: true,
        download_link: true,
      },
    }).then((video) => {
      this.vdo_name = video.vdo_name;
      this.vdo_timeline = video.vdo_timeline;

      //SETTING UP LOCAL REVIEW DATA
      if (
        video.vdo_review != undefined &&
        Array.isArray(video.vdo_review) &&
        video.vdo_review.length > 0
      ) {
        this.review = video.vdo_review;

        //ACTUALIZE TYPO VALUE
        this.actualize_Font_selector_placeholder();
      } else {
        this.review = this.init_review();
        this.triggerAutoSave();
      }

      this.sources = video.vdo_result;
    });

    this.init_animationslist();
    this.fetch_animationModifierFunction();

    //Listen key "enter" to sumbit_review_request()
    document.addEventListener("keydown", (e) => {
      if (e.key == "Enter") {
        this.sumbit_review_request();
      }
    });
  },

  watch: {
    index_currentPart: function (val, oldVal) {
      this.upt_textPart = null;
      this.availiable_features = [];
      this.logoUploaded = null;
      document.querySelector("#currentPart").scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });

      //ACTUALIZE TYPO VALUE
      this.actualize_Font_selector_placeholder();
    },
  },
  computed: {
    ...Vuex.mapGetters(["font_name_list_selector"]),
    index_review_currentPart() {
      let index = this.review.findIndex(
        (elem) =>
          elem.timeline_id == this.vdo_timeline[this.index_currentPart]._id
      );
      return index == -1 ? 0 : index;
    },
    feature_has_modifications() {
      if (
        this.review[this.index_review_currentPart].anim_features &&
        this.review[this.index_review_currentPart].anim_features.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    select_Identity_Font(choice, featureIndex /*, name*/) {
      if (typeof featureIndex == "object" || choice == undefined) {
        return;
      }
      this.current_identity.ident_font = choice;
    },
    placeholder_input: function () {
      if (this.upt_textPart == 0) {
        return "Attention aux fautes d'orthographe";
      } else {
        return "Renommer le fichier (optionnel)";
      }
    },
    select_id_anim: function () {
      if (this.vdo_timeline[this.index_currentPart].clip_type == 0) {
        return this.vdo_timeline[this.index_currentPart].anim_custom_id;
      } else {
        return false;
      }
    },
  },
  methods: {
    triggerAutoSave() {
      this.autoSave.lastKeyUp = new Date();
      this.autoSave.status = 0;
    },
    edit_review_content(value, index) {
      this.review[this.index_review_currentPart].review_content[index].value =
        value;
      this.triggerAutoSave();
    },
    formatColorEntryToHex(entry) {
      if (Array.isArray(entry)) {
        return (
          "#" +
          Math.round(entry[0] * 255)
            .toString(16)
            .toUpperCase() +
          Math.round(entry[1] * 255)
            .toString(16)
            .toUpperCase() +
          Math.round(entry[2] * 255)
            .toString(16)
            .toUpperCase()
        );
      } else if (entry.charAt(0) == "#") {
        return entry;
      } else {
        return "";
      }
    },
    actualize_Font_selector_placeholder() {
      if (this.review[this.index_review_currentPart].anim_features) {
        var fontFeature = this.review[
          this.index_review_currentPart
        ].anim_features.find((feature) => feature.user_input == "font");
        if (fontFeature != undefined && fontFeature.value != undefined) {
          var fontId_toFetch;
          if (
            fontFeature.value != undefined &&
            fontFeature.value._id != undefined
          ) {
            fontId_toFetch = fontFeature.value._id;
          } else {
            fontId_toFetch = fontFeature.value;
          }



          this.get_font_animation(fontFeature.value._id || fontFeature.value)
            .then((font) => {
              //console.log('New New for placeholder : ', font.font_fName.value)
              //this.font_selector_placeholder = font.font_fName.value;
              this.font_selector_placeholder = undefined;
            })
            .catch(() => {
              this.font_selector_placeholder = "Police d'écriture";
            });
        }
      }
    },
    reloadOriginalAnim() {
      this.review[this.index_review_currentPart].anim_features = [];
      this.actualize_Font_selector_placeholder();
      this.triggerAutoSave();
    },
    init_review() {
      let init_review = [];
      for (let i = 0; i < this.vdo_timeline.length; i++) {
        if (this.vdo_timeline[i].clip_type == 1) {
          init_review.push({
            timeline_id: this.vdo_timeline[i]._id,
            review_content: [],
          });
        } else {
          init_review.push({
            timeline_id: this.vdo_timeline[i]._id,
            anim_feature: [],
          });
        }
      }
      return init_review;
    },
    renameFile(originalFile, newName) {
      return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
      });
    },

    ...Vuex.mapActions([
      "init_animationslist",
      "fetch_animationModifierFunction",
      "init_font_animation_name_list",
      "getOneVideo",
      "updateVideo",
      "get_font_animation",
      "get_animation",
    ]),
    type_reviewContent(num) {
      if (num == 0) {
        return "Texte";
      } else {
        return "Visuel";
      }
    },
    duration_render(element) {
      Number.prototype.duration_min = function () {
        var mins = ~~((this % 3600) / 60);
        if (mins < 10) {
          mins = "0" + mins;
        }
        return mins;
      };

      Number.prototype.duration_sec = function () {
        var secs = ~~this % 60;
        if (secs < 10) {
          secs = "0" + secs;
        }
        return secs;
      };

      if (element != undefined) {
        return (
          Number(element).duration_min() + ":" + Number(element).duration_sec()
        );
      } else {
        return "-";
      }
    },
    uploadReviewAttachement(evt) {
      this.file = this.$refs.reviewAttachementUploader.files[0];
      this.sumbit_review_request();
      this.$nextTick(() => {
        this.$refs.reviewAttachementUploader.value = null;
      });
    },
    delete_contentReview(indexContent) {
      // Case visual delete
      if (
        this.review[this.index_review_currentPart].review_content[indexContent]
          .type == 1
      ) {
        var reviewContentBackup =
          this.review[this.index_review_currentPart].review_content[
            indexContent
          ];
        var pathToDel = `/reviews/${this.vdo_id}/${
          this.review[this.index_review_currentPart].timeline_id
        }/${
          this.review[this.index_review_currentPart].review_content[
            indexContent
          ].value
        }`;

        //DELETE REVIEW CONTENT IN REVIEW LOCAL OBJECT (TO DISPLAY CHANGES)
        this.review[this.index_review_currentPart].review_content.splice(
          indexContent,
          1
        );

        axios
          .delete("/videos/review_attachement", {
            data: {
              path: pathToDel,
            },
          })
          .then(() => {
            this.triggerAutoSave();
          })
          .catch((err) => {
            //RE-ADD REVIEW CONTENT BACKUP IN REVIEW LOCAL
            this.review[this.index_review_currentPart].review_content.push(
              reviewContentBackup
            );

            snackbar.show({
              text: "Élément introuvable ou enregistrement encore en cours",
              pos: "top-right",
              showAction: false,
              backgroundColor: snackbar.backgroundColor,
              duration: snackbar.duration,
              textColor: snackbar.colorLevel[3],
            });
            
          });
      } else {
        // Case Text delete
        this.review[this.index_review_currentPart].review_content.splice(
          indexContent,
          1
        );
        this.triggerAutoSave();
      }
    },
    timeUpdate() {
      if (this.$refs.plyr && this.$refs.plyr.player) {
        this.currentTime = this.$refs.plyr.player.currentTime; //x1000 to

        this.index_currentPart = this.vdo_timeline.findIndex(
          (timeline, idx) =>
            this.currentTime >= timeline.appearance_time / 1000 &&
            (this.vdo_timeline[idx + 1] == undefined ||
              this.currentTime <
                this.vdo_timeline[idx + 1].appearance_time / 1000)
        );
      }
    },
    setVideoViewer,
    playPart(index) {
      this.$refs.plyr.player.pause();
      this.$refs.plyr.player.currentTime =
        this.vdo_timeline[index].appearance_time / 1000;

      /*
            this.$nextTick(() => {
                if (this.review[this.index_review_currentPart].anim_features != undefined) {
                    this.features = this.review[this.index_review_currentPart].anim_features;
                }
            });
            */
    },
    change_feature(event, feature) {
      //if anim_features list of review is undefined, create it
      if (
        this.review[this.index_review_currentPart].anim_features == undefined
      ) {
        this.review[this.index_review_currentPart].anim_features = [];
      }

      var reviewfeatureIndex = this.review[
        this.index_review_currentPart
      ].anim_features.findIndex(
        (reviewFeature) =>
          reviewFeature.name == feature.name &&
          reviewFeature.user_input == feature.user_input
      );
      //if feature changements isn't found in review, add it
      if (reviewfeatureIndex == -1) {
        delete feature.value;
        delete feature.applied_value;
        this.review[this.index_review_currentPart].anim_features.push(feature);
        reviewfeatureIndex =
          this.review[this.index_review_currentPart].anim_features.length - 1;
      }

      return (
        new Promise((resolve, reject) => {
          //FONT FEATURE
          if (feature.user_input == "font") {
            this.get_font_animation(event)
              .then((font) => {
                //this.font_selector_placeholder = font.font_fName.value;
                this.font_selector_placeholder = undefined;
                resolve(font);
              })
              .catch(() => {
                this.font_selector_placeholder = "Police d'écriture";
              });

            //IMAGE FEATURE => UPLOAD NEW LOGO
          } else if (feature.user_input == "image") {
            //UPLOAD IMAGE TO API
            file_api
              .post(this.$refs["logoUploader"].files[0])
              .then((media) => {
                var url = `${process.env.API_URL}/medias/${media._id}`;
                //To create blob to display it in logo area
                //this.logoUploaded = URL.createObjectURL(this.$refs["logoUploader"].files[0]);
                this.logoUploaded = url;
                resolve(url);
              })
              .catch((e) => {
                console.log(e);
              });

            //TEXT FEATURE
          } else if (feature.user_input == "text") {
            resolve(event);
          } else {
            resolve(event.target.value);
          }
        })

          //ADD VALUE TO FEATURE COPY IN REVIEW ANIM_FEATURE
          .then((feature_value) => {
            this.review[this.index_review_currentPart].anim_features[
              reviewfeatureIndex
            ].value = feature_value;
            //console.debug('Updated Feature : ', this.review[this.index_review_currentPart].anim_features)

            this.triggerAutoSave();
          })
      );
    },
    //TRIGGERED BY ANIMATION VIEWER COMPONENT
    current_features(features) {
      //GETTING LOGO URL FROM FEATURE VALUE TO DISPLAY IN LOGO AERA
      features.forEach((feature) => {
        if (
          feature.user_input == "image" &&
          feature.applied_value != undefined
        ) {
          this.logoUploaded = feature.applied_value;
        }
      });

      var copyFeatures = JSON.parse(JSON.stringify(features));

      copyFeatures.forEach((feature, featureIndex) => {
        delete copyFeatures[featureIndex].value;
      });

      this.availiable_features = copyFeatures;
    },
    sumbit_review_request() {
      var indexPart = this.index_review_currentPart;
      Promise.resolve()
        .then(() => {
          if (this.upt_textPart == 0) {
            return Promise.resolve();
          }

          //If review Request is a file
          else if (this.upt_textPart == 1) {
            this.autoSave.status = 0;
            while (this.file.name.includes(" ")) {
              this.file = this.renameFile(
                this.file,
                this.file.name.replace(" ", "")
              );
            }
            //TODO: A REVOIR SI PLUSIEURS FICHIER DU MÊME NOM
            if (
              this.review[indexPart].review_content.findIndex(
                (rev) => rev.value == this.file.name
              ) != "-1"
            ) {
              this.file = this.renameFile(
                this.file,
                this.file.name.split(".")[0] +
                  "_copy" +
                  "." +
                  this.file.name.split(".")[1]
              );
            }

            this.input_content = this.file.name;

            return this.uploadRequestAttachement(
              this.file,
              `/reviews/${this.vdo_id}/${this.review[indexPart].timeline_id}/${this.input_content}`
            );
          }
        })
        .then(() => {
          this.review[indexPart].review_content.push({
            time: this.currentTime,
            type: this.upt_textPart,
            value: this.input_content,
          });
          this.reset_review_request_inputs();
          this.triggerAutoSave();
        });
    },
    reset_review_request_inputs() {
      this.input_content = "";
      this.upt_textPart = null;
      this.file = null;
    },
    uploadRequestAttachement(file, path) {
      var formData = new FormData();
      formData.append("files", file);
      formData.append("paths", path);

      return axios.post("/videos/review_attachement", formData, {
        contentType: "multipart/form-data",
      });
    },
    update() {
      //DELETE VALUE FROM FEATURES IMAGE IN ANIM IN REVIEWS (BECAUSE IT'S BLOB)
      var reviewCopy = JSON.parse(JSON.stringify(this.review));
      reviewCopy.forEach((review, reviewIndex) => {
        if (review.anim_features != undefined && review.anim_features != null) {
          review.anim_features.forEach((feature, featureIndex) => {
            if (feature.user_input == "image") {
              //delete reviewCopy[reviewIndex].anim_features[featureIndex].value
            } else if (feature.user_input == "font") {
              //SAVE JUST FONT ID AS VALUE (FONT WILL BE RETRIEVE BY PANEL REVIEW IF NEEDED)
              if (
                reviewCopy[reviewIndex].anim_features[featureIndex].value &&
                reviewCopy[reviewIndex].anim_features[featureIndex].value._id
              ) {
                reviewCopy[reviewIndex].anim_features[featureIndex].value =
                  reviewCopy[reviewIndex].anim_features[featureIndex].value._id;
              }
            }
          });
        }
      });

      return this.updateVideo({
        update: {
          ids: [this.vdo_id],
          vdo_review: reviewCopy,
        },
        queryParams: { replace: true },
      })
        .then((res) => {
          this.reset_review_request_inputs();
          return Promise.resolve(res);
        })
        .catch(() => {
          snackbar.show({
            text: "Erreur d'enregistrement de la review",
            pos: snackbar.pos,
            showAction: false,
            backgroundColor: snackbar.backgroundColor,
            duration: snackbar.duration,
            textColor: snackbar.colorLevel["error"],
          });
          Promise.reject();
        });
    },
    applyToAllTitle() {
      //SAVE CURRENT FEATURE VALUE
      var primaryColor;
      var secondaryColor;
      var textColor;
      var fontId;

      this.review[this.index_review_currentPart].anim_features.forEach(
        (feature) => {
          if (feature.user_input == "font" && feature.value) {
            fontId = feature.value;
          } else if (
            feature.user_input == "color" &&
            feature.value &&
            feature.properties
          ) {
            feature.properties.forEach((property) => {
              if (
                property.color_index != undefined &&
                property.color_index == 0
              ) {
                primaryColor = feature.value;
              } else if (
                property.color_index != undefined &&
                property.color_index == 1
              ) {
                secondaryColor = feature.value;
              } else if (
                property.color_index != undefined &&
                property.color_index == 2
              ) {
                textColor = feature.value;
              }
            });
          }
        }
      );

      //SAVE IN ALL FEATURES
      this.vdo_timeline.forEach(async (clip, clipIndex) => {
        if (clip.clip_type != 0 && clip.anim_custom_id == undefined) {
          return false;
        }

        //RETRIEVE ANIMATION CUSTOM
        var animation_retrieved = await this.get_animation(
          clip.anim_custom_id
        ).catch(() => {
          return null;
        });
        if (
          animation_retrieved == null ||
          animation_retrieved.anim_features == undefined
        ) {
          return false;
        }

        //SAVE FEATURE CONTAINING PROPERTY OF VALUE TO APPLY (EXEMPLE SAVE FEATURE WITCH USE PRIMARY COLOR)
        var featuresToSave = [];
        animation_retrieved.anim_features.forEach((feature) => {
   
          if (feature.user_input == "font") {
            if (fontId != undefined) {
              feature.value = fontId;
              featuresToSave.push(feature);
            }
          } else if (feature.user_input == "color" && feature.properties) {
            feature.properties.forEach((property) => {
              if (
                property.color_index != undefined &&
                property.color_index == 0
              ) {
                if (primaryColor != undefined) {
                  feature.value = primaryColor;
                  featuresToSave.push(feature);
                }
              } else if (
                property.color_index != undefined &&
                property.color_index == 1
              ) {
                if (secondaryColor != undefined) {
                  feature.value = secondaryColor;
                  featuresToSave.push(feature);
                }
              } else if (
                property.color_index != undefined &&
                property.color_index == 2
              ) {
                if (textColor != undefined) {
                  feature.value = textColor;
                  featuresToSave.push(feature);
                }
              }
            });
          }
        });

        //SAVE/MERGE FEATURES IN REVIEW DATA
        featuresToSave.forEach((featureToSave) => {
          if (this.review[clipIndex].anim_features == undefined) {
            this.review[clipIndex].anim_features = [];
          }
          var reviewfeatureFoundIndex = this.review[
            clipIndex
          ].anim_features.findIndex(
            (feature) =>
              feature.name == featureToSave.name &&
              feature.user_input == featureToSave.user_input
          );
          if (reviewfeatureFoundIndex != -1) {
            this.review[clipIndex].anim_features[
              reviewfeatureFoundIndex
            ].value = featuresToSave.value;
          } else {
            this.review[clipIndex].anim_features.push(featuresToSave);
          }
        });
      });
    },
    regenerateVideo() {
      //TRANSFORM BUTTON IN LOADER
      this.$refs.button_regenerate.classList.add("loading");

      /*
      snackbar.show({
        text: "Demande de régénération...",
        pos: snackbar.pos,
        showAction: false,
        backgroundColor: snackbar.backgroundColor,
        duration: snackbar.duration,
        textColor: snackbar.colorLevel["info"],
      });
      */

      return axios.post("/videos/" + this.vdo_id + "/review").catch(() => {
        snackbar.show({
          text: "Problème lors de la demande de régénération...",
          pos: snackbar.pos,
          showAction: false,
          backgroundColor: snackbar.backgroundColor,
          duration: snackbar.duration,
          textColor: snackbar.colorLevel["error"],
        });
        //Fallback
        this.$refs.button_regenerate.classList.remove("loading");
      });
    },
    back() {
      if (this.pannel_updated) {
        this.update().then(() => {
          this.$emit("finished", {});
        });
      } else {
        this.$emit("finished", {});
      }
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped>
</style>