
import axios from 'axios'
import FormData from 'form-data'
import $ from "jquery"


async function setVideoViewer(src, id) {
  const response = await axios.get(src, { responseType: "blob" })

  // return response.data
  $("#"+id).attr('src', window.URL.createObjectURL(response.data))

}

export default setVideoViewer