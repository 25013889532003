<template>
    <div class="panel" panel-position="30">
        <h2>{{video.vdo_name}}</h2>

        <!-- PLAYER-->
        <div class="videoPlayerWrapper center">
            <vue-plyr v-if="sources.files != undefined">
                <video controls crossorigin playsinline>
                    <source v-for="src in sources.files" v-bind:key="src" :size="src.resolution" :src="src.player" type="video/mp4" />
                    <track v-for="sub in sources.subtitles" v-bind:key="sub" kind="captions" :label="sub.label" :src="sub.link" :srclang="sub.language"/>
                </video>
            </vue-plyr>
        </div>

        <!-- INFOS -->
        <div class="info center" style="margin-top: 20px">
            <p>Dernière version</p>
            <p>{{ displayDate(video.vdo_state.state_delivred) }}</p>
        </div>
        <!-- 
        <div class="info center">
            <p>Dernière modifications</p>
            <p>{{ displayDate(video.updated_at) }}</p>
        </div>
        -->
        <div class="info center">
            <p>Durée</p>
            <p>{{ vdo_duration_render(video.vdo_duration) }}</p>
        </div>

        <!-- ACTIONS -->
        <div class="actions center" v-if="video.vdo_state.state_review_editing == undefined || video.vdo_state.state_review_editing == null">
            <p class='button download' @click="downloadVideo"> <img src="../../../../assets/download.svg" /> <a>Télécharger</a></p>
            
            <p class='button' @click="openReviewPanel"> Modifier</p>
        </div>
        <div class="message_video_in_progress center" v-else>
            <p>Vous avez demandé des modifications, celle-ci sont en cours de traitement.<br/>Votre vidéo sera bientôt disponible</p>
        </div>

        <!-- SUBTITLES -->
        <div v-if="video.vdo_state.state_review_editing == undefined || video.vdo_state.state_review_editing == null">
            <h4>Sous-titres</h4>
            <div v-if="sources.subtitles == undefined || sources.subtitles.length == 0" class="subtitle_container center">
                <p class="subtitle_label">
                    Français
                </p>
                <p class="subtitle_action subtitle_request" @click="subtitles_request({vdo_id : video._id})">
                    Générer les sous-titres
                </p>
            </div>
            <div v-for="sub in sources.subtitles" :key="sub.language" class="subtitle_container center">
                <p v-if="sub.label != undefined" class="subtitle_label">
                    {{ sub.label }}
                </p>
                <p v-else class="subtitle_label">
                    {{ subtitle_language_render(sub.language) }}
                </p>
                <p v-if="sub.link != undefined" class="subtitle_action" @click="downloadSubtitles(sub.language)">
                    Télécharger les sous-titres
                </p>
                <p v-else >
                    Sous-titres en cours
                </p>
            </div>
        </div>
    </div>
</template>

<script>

//LIB
import { displayDate } from "../../../../lib/date";
import videoDuration from "../../../../lib/videoDuration";
import snackbar from "../../../../components/Object/snackbar";

//STORE
import Vuex from "vuex"
import store from "../../../../store"

export default {
    store : store,
    props : ["video"],
    emits : ["finished", "mounted"],
    data(){
        return{
             sources: {},
        }
    },
    mounted(){
        if (this.video == undefined){
            this.$emit('finished')
        } else {
            setTimeout(()=>{
                this.$emit('mounted')
            }, 1000)
             
            //FETCH VIDEO FROM API (to render subtitle link - server side) 
            this.getOneVideo({
                id :this.video._id, 
                query: { subtitles: true, download_link: true }
            })
            .then(videoRes => {
                this.sources = videoRes.vdo_result
                
                this.video.vdo_result = videoRes.vdo_result
                //console.debug(this.video)
                //console.debug('REAL DURATION : ', this.video.vdo_duration/1000)
                //console.log('ESTIMATION : ', this.video.estimateDuration)
            })
        }
    },
    methods : {
        ...Vuex.mapActions([
            "getOneVideo",
            "subtitles_request"
        ]),
        displayDate,
        vdo_duration_render: videoDuration.render,
        subtitle_language_render(language){
            switch (language){
                case "fr":
                    return "Français";
                
                case "en":
                    return "Anglais";

                default :
                    return "";
            }
        },
        downloadVideo(){
             if (this.sources.files.full_hd.download != undefined){
                var fileLink = document.createElement("a");
                fileLink.href = this.sources.files["full_hd"].download
                fileLink.setAttribute("download", this.video.vdo_name);
                document.body.appendChild(fileLink);
                fileLink.click();
            } else {
                 snackbar.show({
                    text: "Impossible de télécharger la vidéo",
                    pos: snackbar.pos,
                    showAction: false,
                    backgroundColor: snackbar.backgroundColor,
                    duration: snackbar.duration,
                    textColor: snackbar.colorLevel["error"],
                });
            }
        },
        downloadSubtitles(language){
            //FIND SUBTITLES MATCHED WITH LANGUAGE PARAM
            var subFound = this.sources.subtitles.find(subtitle => subtitle.language == language)
            if (subFound != -1){
                var fileLink = document.createElement("a");
                fileLink.href = subFound.link
                document.body.appendChild(fileLink);
                fileLink.click();
            }
        },
        openReviewPanel(){
            this.$emit('finished', "openPanelReview")
        }
    }

}
</script>

<style src="./VideoInfos.css" scoped></style>