<template>
  <div class="positionAbsolute">
    <div class="selectDropdown" :id="uid">
      <img class="arrow" src="./arrow.svg" />
      <input
        class="chosen-value"
        type="text"
        value=""
        :placeholder="choice_name"
        :style="detectFontNameStyle(choice_name)[0]"
        autocomplete="off" data-lpignore="true" data-form-type="other"
      />
      <div class="value-list">
        <p v-for="choice in choicesObjects" :style="detectFontNameStyle(choice.name)[0]" v-bind:key="choice.value" v-bind:select_value="JSON.stringify(choice.value)">{{ choice.name }}</p>
        <p v-if="loading != undefined && loading == true">Chargement...</p>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery"; //eslint-disable-line
import { v4 as uuidv4 } from "uuid";

//OTHER FUNCTIONS
Array.prototype.toLowerCase = function () {
  for (var i = 0; i < this.length; i++) {
    this[i] = this[i].toString().toLowerCase();
  }
  return this;
};

export default {
  name: "SelectDropdown",
  data: function () {
    return {
      choice_name: "",
      choice_value: "",
      seleted_value_name: "",
    };
  },
  props: ["choices", "placeholder", "field", "value", "loading"],
  events: ["mounted", "select"],
  created() {
    this.uid = uuidv4();
  },
  watch: {
    choices: {
      handler: function (choices) {
        if (this.value != undefined && choices.length != 0) {
          var chc = this.choices.find(choice => 
            choice.value == this.value
          );
          this.$nextTick(() => {
            if (chc != undefined) {
              this.choice_name = chc.name;
              this.set_seleted_value_name(chc.name);
            }
          });
        }
      },
      immediate: true,
    },
    placeholder: function (val) {
      this.choice_name = val
      this.detectFontNameStyle(val);
    }
  },
  computed: {
    choicesObjects: function () {
      if (
        this.choices != undefined &&
        typeof this.choices != undefined &&
        this.choices.length > 0
      ) {
        return this.choices.map((choice) => {
          if (typeof choice == "string") {
            return {
              name: choice,
              value: choice,
            };
          } else {
            return choice;
          }
        });
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.$emit("mounted");

    this.choice_name = this.placeholder || ""

    var inputField = $("#" + this.uid + " > input.chosen-value");
    var dropdown = $("#" + this.uid + " > .value-list");
    var ComponentInstance = this;
    var isOpen = false;

    function closeDropDown() {
      isOpen = false;
      $("#" + ComponentInstance.uid + " > img.arrow").removeClass("open");
      $("#" + ComponentInstance.uid + " > .value-list").removeClass("open");
      $("#" + ComponentInstance.uid + " > .value-list > p").addClass("closed");
      $("#" + ComponentInstance.uid).css({
        //"border": "var(--border_weight) solid var(--color_border)"
      });
    }

    function openDropDown(text) {
      if (isOpen == false) {
        isOpen = true;
        $("#" + ComponentInstance.uid + " > .arrow").addClass("open");
        $("#" + ComponentInstance.uid + " > .value-list").addClass("open");
        $("#" + ComponentInstance.uid).css({
          //"border": "var(--border_weight) solid var(--color1)"
        });
      }

      if (text == undefined || text == "") {
        $("#" + ComponentInstance.uid + " > .value-list").addClass("open");
        $("#" + ComponentInstance.uid + " > .value-list > p").removeClass(
          "closed"
        );
      }
    }

    $("#" + ComponentInstance.uid + " > input.chosen-value").on(
      "change input",
      function (event) {
        $("#" + ComponentInstance.uid + " > .arrow").addClass("open");
        $("#" + ComponentInstance.uid + " > .value-list").addClass("open");
        var inputName = $(event.target).val();
        var inputValue = ""; //$(this).attr("key");

        if (event.type == "input") {
          inputValue = $(event.target).val();
        } else {
          inputValue = $(event.target).attr("select_value");
        }


        if (inputName != "") {
          var regex_value = new RegExp(inputName.toLowerCase(), "g");

          $("#" + ComponentInstance.uid + " > .value-list > p").each(
            function () {
              var listValue = $(this).text().toLowerCase();

              if (regex_value.exec(listValue) != null) {
                //If inputValue match with part of li value
                $(this).removeClass("closed");
              } else {
                $(this).addClass("closed");
              }
            }
          );
        } else {
          //Display all proposals
          $("#" + ComponentInstance.uid + " > .value-list").addClass("open");
          $("#" + ComponentInstance.uid + " > .value-list > p").removeClass(
            "closed"
          );
        }
        if (event.type == "change") {
          //VERIFY IF INPUT NAME IS INCLUDE IN CHOICES (Or if it's just a text search)
          if (ComponentInstance.choicesObjects.map(choice => choice.name.toLowerCase()).includes(inputName.toLowerCase())){
            ComponentInstance.$emit( "select", JSON.parse(inputValue), ComponentInstance.field, inputName );
            ComponentInstance.set_seleted_value_name(inputName);
          }
          $(this).val("");
        }
      }
    );

    $("#" + ComponentInstance.uid).hover(
      function () {
        //$("#" + ComponentInstance.uid + " > img.arrow").addClass("open");
      },
      function () {
        var choiceElements = $(
          "#" + ComponentInstance.uid + " > .value-list > p"
        );

        if (choiceElements.length > 0 && choiceElements.hasClass("closed")) {
          closeDropDown();
        } else if (choiceElements.length == 0) {
          closeDropDown();
        }
      }
    );

    $("#" + this.uid + " > .value-list > p").on("click", function () {});

    //Close Dropdown on click elsewhere
    document.addEventListener("click", (evt) => {
      var isDropdown = $(dropdown).is($(evt.target));
      var isInput = $(inputField).is($(evt.target));

      var isDropdownElement = false;
      $(evt.target)
        .parents()
        .each(function () {
          if (
            !isDropdownElement &&
            $(this).attr("id") == ComponentInstance.uid
          ) {
            isDropdownElement = true;
          }
        });

      if (!isDropdown && !isInput && !isDropdownElement) {
        //If it's not an element of the Componenent -> Hiding
        closeDropDown();
      } else {
        // Clicked on component
        if (isInput == true) {
          if (isOpen == false) {
            openDropDown();
          } else if (isOpen == true) {
            closeDropDown();
          }
        }
      }
    });

    $(
      "#" +
        ComponentInstance.uid +
        " > .value-list, #" +
        ComponentInstance.uid +
        " > .value-list > p"
    ).on("click", function (event) {
      $("#" + ComponentInstance.uid + " > input.chosen-value")
        .val($(event.target).text())
        .attr("select_value", $(event.target).attr("select_value"))
        .trigger("change"); //There is change event.type "change" that can emit select choice outfrom Compononent

      closeDropDown();
    });
  },
  methods: {
    set_seleted_value_name(value) {
      this.seleted_value_name = value;
      var detectionresult = this.detectFontNameStyle(value);
      //console.debug(detectionresult)
      if (detectionresult.length > 0) {
        $(".chosen-value").css({
          "--placeholder_ffamily": detectionresult[1],
          "--placeholder_fweight": detectionresult[2],
        });
      }
    },
    detectFontNameStyle(value) {
      try {
        value = value.replace(/\"\'/g, "");
        //console.debug(value)
        var fFamlily = value.split("-")[0];
        //console.debug("fFamlily found in selectDropDown : " + fFamlily)
        var fStyle = value.split("-")[1].toLowerCase();
        switch (fStyle) {
          case "thin":
            fStyle = 100;
            break;

          case "light":
            fStyle = 200;
            break;

          case "regular":
            fStyle = 400;
            break;

          case "medium":
            fStyle = 500;
            break;

          case "semibold":
            fStyle = 600;
            break;

          case "bold":
            fStyle = 700;
            break;

          case "black":
            fStyle = 800;
            break;

          default:
            fStyle = 400;
            break;
        }

        return [
          "font-family : '" + fFamlily + "'; font-weight : " + fStyle,
          fFamlily,
          fStyle,
        ];
      } catch (e) {
        //console.debug('Impossible to parse potential font family '+e)
        return ["", "", ""];
      }
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped></style>