<template>
    <div class="">
        <div class="listEmpty" v-if="
              getVideos('delivred') != undefined &&
              getVideos('delivred').length == 0 
            ">
            <h3>Vous n'avez pas encore de vidéo réalisée avec Yopbox !</h3>
            <p>
                Toujours aucune vidéo produite ? C'est pourtant si simple ! Pour ce faire, complétez intégralement une vidéo en cours de création, puis commandez-la nous !
            </p>
            <button class="button" @click="$router.push({name :'creation'})">
              Revenir aux vidéos en création
            </button>
        </div>
        <!-- PanelReview -->
        <transition name="panel-slide">
            <PanelReview v-if="panel == 'PanelReview'" :vdo_id="vdo_selected._id" @finished="loadVideos(); panelCb()" class="childComponent" />
        </transition>
    
        <!-- Panel video info -->
        <transition name="panel-slide">
            <VideoInfos v-if="panel == 'VideoInfos'" :video="vdo_selected" @finished="panelCb" @mounted="childPanelMounted = true" class="childComponent" />
        </transition>
    
        <div class="cardList center">
            <div v-for="video in getVideos('delivred')" v-bind:key="video" class="card" @click="openPanel('VideoInfos', video)" :class="{unclickable : in_processing(video)}">
    
                <!-- BACKGROUND BLURED -->
                <div class="visual_container">
                    <img class="bluredBackground" v-if="video.vdo_result != undefined && video.vdo_result.thumbnail != undefined" :src="video.vdo_result.thumbnail" />
                    <img class="bluredBackground" v-else src="../../../assets/vdo_thumbnail_placeholder.jpeg" />
                </div>
    
                <!-- VISUAL OF CARD -->
                <div class="visual_container">
                    <p class="video_duration">{{ vdo_duration_render(video.vdo_duration) }}</p>
                    <img v-if="video.vdo_result != undefined &&video.vdo_result.thumbnail == 'https://i.vimeocdn.com/video/default'" src="../../../assets/vdo_thumbnail_placeholder.jpeg" />
                    <img v-else-if="video.vdo_result != undefined && video.vdo_result.thumbnail != undefined" :src="video.vdo_result.thumbnail" />
                    <img v-else src="../../../assets/vdo_thumbnail_placeholder.jpeg" />
                </div>
    
                <!-- PROCESSING/REVIEW LABEL -->
                <span v-if="in_processing(video)" class="render_processing_message">{{render_processing_message(video)}}</span>
    
    
                <div class="card-bottom">
                    <div class="flex_spacebewteen">
                        <p class="card-title">{{ video.vdo_name }}</p>
                    </div>
                    <div class="flex_spacebewteen center">
                        <p class="card-date">{{ displayDate(video.vdo_state.state_delivred) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//STORE
import Vuex from "vuex";

//COMPONENTS
import PanelReview from "../../../components/Panels/panelReview/panelReview.vue";
import VideoInfos from './VideoInfos/VideoInfos.vue'

//LIB
import { displayDate } from "../../../lib/date";
import videoDuration from "../../../lib/videoDuration";
import $ from "jquery";

export default {
    data() {
        return {
            panel: null,
            childPanelMounted: false,
            vdo_selected: null
        }
    },
    components: {
        PanelReview,
        VideoInfos
    },
    mounted() {
        document.addEventListener("click", (evt) => {
            if (this.childPanelMounted == true) {
                var clickOnChildComponent = false

                if ($(evt.target).hasClass('childComponent')) {
                    clickOnChildComponent = true
                }

                $(evt.target).parents().get().forEach(parent => {
                    if ($(parent).hasClass('childComponent')) {
                        clickOnChildComponent = true
                    }
                })

                if (!clickOnChildComponent) {
                    this.panel = null
                    this.childPanelMounted = false
                }
            }
        })
    },
    computed: {
        ...Vuex.mapGetters([
            "getVideos",
            "vdoHasSubitles"
        ])
    },
    methods: {
        ...Vuex.mapActions([
            "loadVideos",
        ]),
        displayDate,
        vdo_duration_render: videoDuration.renderShort,
        render_processing_message(video) {
            var firstVersion = false
            if (video.vdo_result == undefined) {
                firstVersion = true
            } else if (video.vdo_result.version == undefined) {
                firstVersion = true
            } else if (video.vdo_result.version == 0) {
                firstVersion = true
            }

            if (firstVersion) {
                return "Génération en cours..."
            } else {
                return "Régénération en cours..."
            }
        },
        in_processing(video) {
            if (video.vdo_state.state_review_editing != undefined && video.vdo_state.state_review_editing != null) {
                return true
            } else if (video.vdo_state.state_delivred == undefined || video.vdo_state.state_delivred == null) {
                return true
            } else {
                return false
            }
        },
        openPanel(panelName, video) {
            this.$nextTick(() => {
                this.vdo_selected = video
                this.panel = panelName
            })

        },
        panelCb(cb) {
            switch (cb) {
                case "openPanelReview":
                    this.panel = "PanelReview"
                    break;

                default:
                    this.panel = null
                    break;
            }
            this.childPanelMounted = false
        }
    }
}
</script>

<style src="./../list.css" scoped>

</style>

<style src="./Videos_delivred.css" scoped>

</style>

<style src="../listEmpty.css" scoped>

</style>